import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
const firebaseConfig = {
  apiKey: "AIzaSyAuTmFWwSUMAM_2RoVOcJYj9YjzWiaZdDY",
  authDomain: "rakhib2-56387.firebaseapp.com",
  projectId: "rakhib2-56387",
  storageBucket: "rakhib2-56387.appspot.com",
  messagingSenderId: "602596037050",
  appId: "1:602596037050:web:8c7777188a47004be8cd83",
  measurementId: "G-W6NJSLBJQF"
};

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export default db;